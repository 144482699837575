<template>
    <Popup 
        size="lg"
        modal-id="bundle-module-create-remark"
        custom-class="gray-header"
        :on-close="close"
        :scrollable="true"
        :toggle-status="showPopup"
    >
        <template v-slot:header>
            <div class="bundle-module-remark__header">
                <div class="title">{{ labels.create_remark }}</div>
            </div>
        </template>
        <template v-slot:body>
			<FormulateForm id="create-bundle-module-remark">
                <FormulateInput 
                    type="text"
                    name="title"
                    v-model="values.title"
                    :label="labels.title"
                    error-behavior="blur"
                    class="bundle-remark-input"
                />
                <FormulateInput
                    v-model="values.text"
                    type="textarea"
                    name="text"
                    :label="labels.note"
                    error-behavior="blur"
                    class="bundle-remark-input"
                />
            </FormulateForm>
		</template>
        <template v-slot:footer>
            <Button
				:label="labels.cancel"
				variant="btn-outline-purple"
				data-dismiss="modal"
				style="fontWeight: 600"
				:action="close"
			/>
            <Button
                :disabled="createBtnDisabled"
				:label="labels.create"
				variant="btn-purple"
				data-dismiss="modal"
				style="fontWeight: 600"
			    :action="createRemarks"
			/>
            
        </template>
    </Popup>
</template>

<script>
import Popup from '@/components/Popups/Popup';
import Button from '@/components/Buttons/Button';

import { mapActions, mapGetters } from 'vuex';

export default {
    name: "BundleModuleRemarkCreate",
    components: {
        Popup,
        Button
    },
    // props: {
	// 	modalId: {
	// 		type: String,
	// 		required: true
	// 	},
	// },
    data() {
        return {
            createBtnDisabled: false,
            showPopup: true,
            values: {
                title: '',
                text: ''
            }
        }
    },
    methods: {
        ...mapActions( 'notification', ['addNotification']),
        ...mapActions('account', ['createRemark']),
        close() {
            this.values.title = ''
            this.values.text = ''
            this.openParentPage();
		},
        createRemarks() {
            this.createRemark({
                ...this.values,
                module: this.$route.params.module_id
            }).then((res)=>{
                if (res.success) {
                    this.addNotification( { variant: 'success', msg: this.labels.remark_added } );
                    this.openParentPage();
                }

                if (res.error?.length) {
                    this.addNotification( { variant: 'danger', msg: res.error } );
                }
            }).catch((err) => console.log(err));
        },
        openParentPage() {
            this.$router.push(
                {
                    name: "bundle-module",
                    params: {
                        monetization_bundle_id: this.$route.params.monetization_bundle_id,
                        module_id: this.$route.params.module_id
                    }
                }
            );
        }
    },
    computed: {
        ...mapGetters('helper', ['labels']),
    }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .formulate-input .formulate-input-element {
    max-width: 100% !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important
  }
  .formulate-input {
    textarea {
        height: 130px;
    }
  }
}
.bundle-remark-input {
    font-family: "Oswald", sans-serif;
    line-height: 1.5;
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0.1em;
}
</style>